<template>


  <NuxtLink class="hrow" :to="props.item_diary.link">

    <img loading="lazy" class="diary_avatar" :src="props.item_diary.avatar_little">

    <div class="diary_name">{{ props.item_diary.name }}</div>

    <div class="sp"></div>

    <div class="stat stat_gp">
      <span class="ic gp"></span>
      <div class="vl">
        {{ props.n_plant && props.n_weight ? Math.round(props.n_weight/props.n_plant) : '-' }}
      </div>
      <div class="un" title="gram/plant">g/plant</div>
    </div>   

    <div class="stat stat_w">
      <span class="ic weight"></span>
      <div class="vl">
        <template v-if="props.n_weight">
          {{ $convert.view('weight', useAuth().getWeight(), props.n_weight, 0) }}
          {{ UnitWeightTr[useAuth().getWeight()] ? $t(UnitWeightTr[useAuth().getWeight()]) : '' }}
        </template>
        <template v-else>
          -
        </template>
      </div>
      <div class="un" title="gram">Weight</div>                
    </div>

 
 

    <div class="user">
      {{ props.item_user.name }}
    </div>

    <!-- <div class="date">
      {{ $dayjs(props.add_date).utc().fromNow() }}
    </div> -->


  
  </NuxtLink>

</template>


<script setup lang="ts">

import type { DiaryShort, DiaryEquip } from '@/types/diary';
import type { UserShort } from '@/types/user';
import { UnitWeightTr } from '@/types/other';

const { $convert, $constants, $dayjs } = useNuxtApp();

interface PageProps {
  n_weight: number | null;
  n_plant: number | null;
  add_date: string;
  strain_general: any;
  item_diary: DiaryShort;
  item_diary_seed: DiaryEquip;
  item_user: UserShort;
}

const props = defineProps<PageProps>();

</script>

<style scoped>
 
.hrow{
  display: grid;
  width: 100%;
  grid-template-columns: 40px fit-content(200px) auto 120px 100px;
  grid-template-areas: 
  "diary_avatar diary_name diary_name stat_w stat_gp"
  "diary_avatar user sp stat_w stat_gp"
  ;
  align-items: center;
  gap: 0 1rem;
  padding: 0.4rem;
  break-inside: avoid;
  contain: content;
}

.hrow:nth-child(even) {
  background-color: var(--un-background-color-gray);
}

.diary_avatar{
  width: 40px;
  border-radius: 5px;
  aspect-ratio: 1/1;
  object-fit: cover;
  grid-area: diary_avatar;
}

.diary_name{
  grid-area: diary_name;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sp{
  grid-area: sp;
}
.stat{
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-template-areas:
  "ic vl"
  "ic un";

}
.stat .ic{
  grid-area: ic;
  width: 32px;
  height: 100%;
  background-position-y: center;
}
.stat .vl{
  grid-area: vl;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.stat .un{  
  grid-area: un;
  color: gray;
  font-size: 0.7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.stat.stat_gp{
  grid-area: stat_gp;
}

.stat.stat_rt{
  grid-area: stat_rt;
}

.stat.stat_rt .star_rate{ 
  width: 17px;
}

.stat.stat_w{ 
  grid-area: stat_w;
}

.user{
  grid-area: user;
  color: gray;
  font-size: 0.7rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* .date{
  grid-area: date;
  color: gray;
  font-size: 0.7rem
} */



@container pb (max-width: 768px) {
  
  .hrow{
    grid-template-columns: 40px fit-content(100px) fit-content(100px) fit-content(100px) 1fr 80px;
    grid-template-areas: 
    "diary_avatar diary_name diary_name diary_name diary_name stat_gp"
    "diary_avatar stat_w stat_w user sp stat_gp"
    ;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0;
    gap: 0 0.6rem;
  }

  .hrow:nth-child(even) {
    background: transparent;
  }
  /* .date{
    display: none;
  } */

  .stat.stat_w{
    grid-template-columns: 20px 1fr;
    grid-template-areas: 
    "ic vl";
  }
  .stat.stat_w .ic{
    width: 20px;
  }
  .stat.stat_w .un{
    display: none;
  }

  .stat.stat_rt .un{
    display: none;
  }

  .user{
    font-size: 0.8rem;
  }
  .stat.stat_w .vl{
    font-size: 0.8rem;
  }
  .stat.stat_rt .vl{
    font-size: 0.8rem;
  }
  .stat .vl{
    font-size: 1.2rem;
  }

}



</style>
